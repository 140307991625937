<template>
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">疾病防控列表</div>
      <div>
        <el-button class="sheet-btn" @click="handleReport" v-if="isReport">
          上报
        </el-button>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="downLoadFile"
          v-if="isExport"
        >
          导出
        </el-button>
      </div>
    </div>
    <!-- 表单 -->
    <el-form :model="form" onsubmit="return false" :inline="true">
      <el-form-item>
        <el-select v-model="form.classId" clearable placeholder="选择患病班级">
          <el-option
            v-for="item in moduleClassOptions"
            :key="item.label"
            :label="item.name"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.levelValue" clearable placeholder="严重程度">
          <el-option
            v-for="item in contagionLevelList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.NameValue" clearable placeholder="传染病名称">
          <el-option
            v-for="item in contagionNameList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="dateTime"
          type="daterange"
          range-separator="～"
          value-format="yyyy-MM-dd"
          start-placeholder="开始上报日期"
          end-placeholder="结束上报日期"
          :picker-options="pickerOptions"
          class="form-date-time"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-input
          v-model.trim="form.reportName"
          placeholder="输入上报人"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.statusValue"
          clearable
          placeholder="选择流程状态"
        >
          <el-option
            v-for="item in contagionStatusList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun">
          查询
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button @click="resetFun" icon="el-icon-refresh-left" class="reset">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table
      id="editAbletrendsTable"
      :data="tableData"
      :header-cell-style="{
        background: '#F1F4F9',
        color: '#5E6D9B',
        fontWeight: '400',
        'text-align':'center'
      }"
      show-summary
      :summary-method="getSummaries"
      v-loading="tableLoading"
      @sort-change="sortChange"
    >
      <el-table-column
        type="index"
        label="序号"
        min-width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="className"
        label="患病班级"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sickNum"
        label="患病人数"
        min-width="110"
        sortable="custom"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="level.label"
        label="严重程度"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="contagionName.label"
        label="传染病名称"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reportName"
        label="上报人"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reportTime"
        label="上报日期"
        min-width="170"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="feedbackTime"
        label="结束日期"
        min-width="170"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reportStatus.label"
        label="流程状态"
        min-width="130"
        align="center"
      >
        <template slot-scope="{ row }">
          <span
            :class="
              row.reportStatus.value == '1' || row.reportStatus.value == '2'
                ? 'activeColor'
                : 'defaultColor'
            "
          >
            {{ row.reportStatus.label }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="130" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handleJumplook(scope.row)"
            type="text"
            size="medium"
            v-if="isView"
          >
            查看
          </el-button>
          <el-button
            @click="handleJumpEdit(scope.row)"
            type="text"
            size="medium"
            v-if="scope.row.reportStatus.value == '2' && isEdit"
          >
            编辑
          </el-button>
          <el-button
            @click="handleJumpFeedback(scope.row)"
            type="text"
            size="medium"
            v-if="scope.row.reportStatus.value == '3' && isOverdueFeedback"
          >
            反馈
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="manger-footer">
      <div class="footer-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout="total,sizes,prev,pager,next,jumper"
          :total="pagination.total"
          :page-size="pagination.size"
          @current-change="handleCurrentChangePage"
          :current-page.sync="pagination.currentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import downloadFile from "@/utils/downloadFile";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      form: {
        classId: "",
        levelValue: "",
        NameValue: "",
        beginTime: "",
        endTime: "",
        reportName: "",
        statusValue: "",
      },
      dateTime: "",
      moduleClassOptions: [], // 所属班级下拉
      contagionLevelList: [], //严重程度
      contagionNameList: [], //传染病名称
      contagionStatusList: [], //流程状态
      tableData: [], //主表格数据
      totalCount: "", //主表格患病数
      pagination: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      dialogForm: {},
      tableLoading: false, // 表格loading
      // organizationList: [],
      permissionButtonList: [], // 权限按钮list
      columnOrder: "",
      pickerOptions: {
        disabledDate(times) {
          return times.getTime() > Date.now();
        },
      },
      isView: false,
      isExport: false,
      isReport: false,
      isEdit: false,
      isOverdueFeedback: false,
    };
  },

  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
    }),
  },

  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          console.log(val, "chuanranbingfengongsi");
          this.isView = val.indexOf("y_infectiousDisease:view") != -1;
          this.isExport = val.indexOf("y_infectiousDisease:export") != -1;
          this.isReport = val.indexOf("y_infectiousDisease:report") != -1;
          this.isEdit = val.indexOf("y_infectiousDisease:edit") != -1;
          this.isOverdueFeedback =
            val.indexOf("y_infectiousDisease:overdueFeedback") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },

  created() {
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },

  mounted() {
    this.tableLoading = true;
    // this.init();
    this.getClassInfoList(); //班级
    this.getContagionLevel(); //传染病严重程度
    this.getContagionName(); //传染病名称
    this.getContagionStatus(); //流程状态
  },
  activated(){
    this.init();
  },

  methods: {
    getPermissionButton,
    //排序
    sortChange(column) {
      // console.log(column);
      // console.log(column.prop, column.order);
      this.columnOrder = column.order;
      this.init(column.order);
    },

    /** 初始化 */
    init(order) {
      let dateTime0 = "";
      let dateTime1 = "";
      if (this.dateTime !== null) {
        dateTime0 = this.dateTime[0];
        dateTime1 = this.dateTime[1];
      }
      let params = {
        sort: order,
        flag: "campus",
        classLabel: this.form.classId,
        level: this.form.levelValue,
        contagionName: this.form.NameValue,
        beginTime: dateTime0,
        endTime: dateTime1,
        reportName: this.form.reportName,
        reportStatus: this.form.statusValue,
        current: this.pagination.currentPage,
        size: this.pagination.size,
      };
      this.$api.getDiseaseLog(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data.list.records;
          this.totalCount = res.data.data.totalCount;
          this.pagination.total = res.data.data.list.total;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //上报
    handleReport() {
      this.$router.push({
        path: "/diseaseReport",
        query: {
          lookSubStatus: "0",
        },
      });
    },

    //操作查看
    handleJumplook(row) {
      this.$router.push({
        path: "/diseaseReport",
        query: {
          lookId: row.id,
          lookSubStatus: "2",
        },
      });
    },

    //操作编辑
    handleJumpEdit(row) {
      this.$router.push({
        path: "/diseaseReport",
        query: {
          lookId: row.id,
          lookSubStatus: "3",
        },
      });
    },

    //反馈
    handleJumpFeedback(row) {
      this.$router.push({
        path: "/endback",
        query: {
          lookId: row.id,
        },
      });
    },

    //表尾合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }

        if (index === 2) {
          sums[index] = this.totalCount;
          return;
        }
      });

      return sums;
    },

    //分公司对比合计
    getSummariesDialog(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }

        if (index === 2) {
          sums[index] = this.gridData.total;
          return;
        }
      });

      return sums;
    },

    //查询班级
    getClassInfoList(valPark) {
      let params = {
        parentLabel: valPark,
      };
      this.$api.getClassInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleClassOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 获取字典传染病严重程度
    getContagionLevel() {
      let params = {
        type: "contagion_level",
      };
      this.$api.getDictionary(params).then((res) => {
        if (res.data.code == 0) {
          this.contagionLevelList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 获取字典传染病传染病名称
    getContagionName() {
      let params = {
        type: "contagion_name",
      };
      this.$api.getDictionary(params).then((res) => {
        if (res.data.code == 0) {
          this.contagionNameList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 获取字典流程状态
    getContagionStatus() {
      let params = {
        type: "contagion_report_status",
      };
      this.$api.getDictionary(params).then((res) => {
        if (res.data.code == 0) {
          this.contagionStatusList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //导出
    downLoadFile() {
      if (this.tableData.length > 0) {
        let dateTime0 = "";
        let dateTime1 = "";
        if (this.dateTime !== null) {
          dateTime0 = this.dateTime[0];
          dateTime1 = this.dateTime[1];
        }
		let userInfo = sessionStorage.getItem("userInfo")
		  ? JSON.parse(sessionStorage.getItem("userInfo"))
		  : {};
        //下载文件
        let url = "/contagion/report-record/exportList";
        let data = {
          sort: this.columnOrder,
          flag: "campus",
		  companyLabel: userInfo.schoolList[0].companyLabel,
		  campusLabel: userInfo.schoolList[0].schoolLabel,
          classLabel: this.form.classId,
          level: this.form.levelValue,
          contagionName: this.form.NameValue,
          beginTime: dateTime0,
          endTime: dateTime1,
          reportName: this.form.reportName,
          reportStatus: this.form.statusValue,
          current: this.pagination.currentPage,
          size: this.pagination.size,
        };

        let type = "get";

        this.tableLoading = true;
        downloadFile({ url, data, type }).then(() => {
          this.tableLoading = false;
        });
      } else {
        this.$message.warning("暂无数据，不能导出！");
      }
    },

    // 查询
    searchFun() {
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    // 重置
    resetFun() {
      this.form = {
        classId: "",
        levelValue: "",
        NameValue: "",
        reportName: "",
        statusValue: "",
      };
      this.dateTime = "";
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    /** 行内-查看 */
    handleCheck(row) {
      this.dialogForm = {
        type: row.type,
        title: row.title,
        remoteAddr: row.remoteAddr,
        method: row.method,
        serviceId: row.serviceId,
        time: row.time,
        createTime: row.createTime,
      };
      this.dialogVisible = true;
    },

    /** 行内-删除 */
    handleDel(row) {
      this.$confirm(`是否确认删除id为${row.id}的日志?`, {
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
        this.$api.delLog(row.id).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            this.tableLoading = true;
            this.init();
          }
        });
      });
    },

    /** 分页 */
    handleCurrentChangePage(val) {
      this.pagination.currentPage = val;
      this.tableLoading = true;
      this.init();
    },

    handleSizeChange(val) {
      this.pagination.size = val;
      this.tableLoading = true;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.contain {
  .description-div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .form-header-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .sheet-btn {
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  /deep/.el-table {
    // margin-top: 14px;
  }

  /deep/.el-dialog__body {
    padding: 0 20px 30px;
  }

  .dialog-sheetbtn {
    margin: 10px 0 20px;
  }

  .form-date-time{
    width: 270px;
  }
}

.manger-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 1px;

  .footer-left-title {
    color: #606266;
  }
  .footer-left-num {
    color: red;
  }
}

.allData {
  width: 300px;
  white-space: normal;
}

.handleData {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
